body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  background-color: #1a1a1a;
  margin: 0;
  padding: 0;
  height: 100vh;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: #1a283e;
}

::-webkit-scrollbar:horizontal {
  height: 4px;
}

::-webkit-scrollbar-thumb:horizontal {
  background-color: #1a283e;
}

.principal {
  display: flex;
  background-color: white;
}

.management-form-page {
  /* display: flex; */
  width: calc(100vw - 200px);
  padding: 5px;
  justify-content: center;
  align-items: start;
  margin-left: 200px;
  min-height: 100vh;
  min-width: 600px;
}

.management-form-container {
  width: 100vw;
  display: inline;
}

.table-container {
  width: 100%;
}

.header-table {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.h1-table {
  padding-left: 30px;
  color: #1a283e;
  font-size: 30px;
  font-weight: 600;
}

.btn-circular-table {
  margin-left: auto;
  padding-right: 30px;
  /* Adicionei padding direito para garantir algum espaço à direita do botão */
}

.modal-style {
  position: fixed;
  top: 50%;
  left: 50%;
  height: 100%;
  width: 100%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  color: black;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 20px;
  max-height: 95%;
  overflow-y: auto;
}

.modal-container {
  padding: 10px;
  overflow: hidden;
  border-radius: 10px;
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 95%;
  width: 50%;
  align-items: center;
  justify-content: center;
}

.btn-open-modal-form {
  float: right;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  background-color: #05b654;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 100px;
  height: 40px;
}

.btn-open-modal-form:hover {
  background-color: #00ab4d;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 2px;
  text-align: start;
}

.form-title {
  color: #1a283e;
  margin-bottom: 14px;
  font-size: 25px;
  font-weight: 600;
}

.custom-input {
  color: red;
}

.custom-input:hover {
  border-color: #1a283e;
  /* Substitua pelo código de cor desejado */
}

.select-form {
  width: 80vh;
  padding: 10px;
  margin: 5px 0;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  display: block;
}

.form-input {
  width: 80vh;
  padding: 10px;
  margin: 3px 0;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  display: block;
}

.form-description {
  width: 80vh;
  padding: 10px;
  margin: 5px 0;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  display: block;
}

.react-toast {
  position: relative;
  float: right;

  z-index: 99;
}

.container-404 {
  color: #ccc;
  display: flex;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.switch-active-user {
  margin-top: 39px;
  margin-left: 4px;
}

.switch-active-user .form-check-label {
  margin-left: 8px;
}

.switch-active-user .form-check-input {
  transform: scale(1.55);
}

.switch-active-user .form-check-input:checked {
  background-color: rgb(32, 197, 32);
}

.modal-content{
  /* min-width: 400px; */
}