.input-login {
  margin-bottom: 10px;
  height: 50px;
  font-size: 20px !important;
  font-weight: 500 !important;
}

.btn-submit-login {
  background-color: #cd7c14 !important;
  border-color: #cd7c14 !important;
  color: aquamarine;
  height: 50px;
  font-size: 25px;
  margin-top: 10px;
}

.login-img-container{
  display: flex;
  justify-content: center;
  align-content: center;
  width: 300px;
  height: 100px;
  position: relative;
  overflow: hidden;
}

.img-center{
  align-self: center;
}

.login-img{
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%; 
   left: 50%;
  transform: translate(-50%, -50%);
}

.container-to-center{
  display: flex;
  justify-content: center;
  align-content: center;
}

.error-message{
  color: rgb(214, 22, 22);
}

.btn-submit-login:hover {
  background-color: #bb6d06 !important;

}

