.condominium {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.btn-cond-logout {
  opacity: 5%;
  height: 40px;
  width: 80px;
  float: right;
}

.btn-cond-logout:hover {
  opacity: 100%;
}

.condominium-logo-container {
  display: flex;
  justify-content: center;
}

.condominium .slick-slider {
  width: 90vw;
  height: calc(100vh - 100px);
}

.condominium .slick-slide img {
  width: 90vw;
  height: calc(100vh - 50px);
  object-fit: contain;
}

.logo-img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.condominium-video {
  width: 100%;
  height: calc(100vh - 25vh - 50px);
  object-fit: cover;
}
.condominium-footer-image {
  height: 25vh;
}
