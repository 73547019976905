.operador {
  height: 100vh;
  padding: 30px;
  min-width: 800px;
}

.operador-painel {
  min-height: 100px;
  min-width: 300px;
  display: flex;
  flex-direction: row;
  height: 20vh;
  justify-content: space-between;
  align-items: center;
  border-width: 2px;
  border-radius: 10px;
  border-color: rgb(69, 70, 70);
  border-style: solid;
}

.operador-video {
  padding: 2px;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(48, 48, 46);
  height: 100%;
  width: 250px;
}

.operador-logo-strike {
  height: 100%;
  padding-right: 180px;
}

.btn-operador-logout {
  margin-right: 4px;
  height: 40px;
  width: 80px;
  float: right;
}

.btn-operador-logout:hover {
  opacity: 100%;
}

.operador-active-cond {
  padding-top: 14px;
  margin-top: 6px;
  height: 40px;
  border-style: solid;
  border-color: rgb(69, 70, 70);
  border-radius: 10px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: rgb(83, 216, 21);
  font-weight: 600;
}

.operador-cond-list {
  margin-top: 6px;
  height: calc(100vh - 20vh - 40px - 30px - 40px);
  border-style: solid;
  border-color: rgb(69, 70, 70);
  border-radius: 10px;
}

.opreador-table {
  margin: 0;
  width: 100%;
  overflow: auto;
}

.table-operador {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  font-weight: 600;
  color: #f0f0f0;
  background-color: #222;
  margin: 20px 0;
}

.operador-th,
.operador-td {
  padding: 6px;
  text-align: left;
}

.operador-th {
  background-color: #111110;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #ffffff;
  font-weight: bold;
}

.operador-tr {
  text-align: center;
  cursor: pointer;
}

.operador-tr:nth-child(even) {
  background-color: #2a2a2a;
}

.operador-tr:hover {
  background-color: #292012d8;
  color: #fff;
}

.active-row {
  background-color: #cd7c14 !important;
  color: #1a1818 !important;
}

.red {
  color: crimson !important;
}

.fail-load-condlist {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fail-text {
  color: #f0f0f0;
  font-size: 20px;
}
