.sidebar {
  width: 200px;
  height: 100%;
  background-color: #312f2f;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 3px 0px 2px rgba(0, 0, 0, 0.2);
  position: fixed;
  justify-content: space-between;
}

.sidebarSmall {
  display: none;
  width: 100%;
  height: 60px;
  background-color: #312f2f;
  flex-direction: row;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.2);
  position: fixed;
}

@media (max-width: 576px) {
  .sidebar {
    display: none;
  }

  .modal {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    
  }
  .sidebarSmall {
    display: flex;
  }
  .modal-backdrop {
    display: none;
  }
  .menu-item {
    list-style: none;
    padding: 0 !important;
    margin: 0 !important;
  }
  .menu-text {
    margin: 0 !important;
    padding: 6px !important;
  }
  .container-menu-item {
    display: flex;
  }
  .principal {
    width: fit-content;
    height: 100dvh;
  }
  .management-form-page {
    padding-top: 80px;
    padding-left: 10px;
    margin-left: 0;
    height: fit-content;
  }
}

.menu-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

.logo-container {
  background-color: #312f2f;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.logo {
  width: 180px;
}

.menu {
  list-style: none;
  margin-top: 1px;
  display: flex;
  flex-direction: column;
}

.menu-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin-top: 0;
}

.menu-item-logout {
  list-style: none;
  display: flex;
  align-items: center;
  padding: 8px;
  width: 100%;
}

.container-menu-item-logout {
  width: 100%;
}

.container-menu-item-logout:hover {
  background-color: rgb(209, 29, 29);
}

.menu-icons {
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-right: 20px;
  margin-left: 10px;
  font-size: 24px;
}

.menu-text {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.container-menu-item:hover {
  background-color: rgb(207, 101, 52);
  font-weight: 600;
}

.side-active {
  background-color: rgb(207, 101, 52);
  font-weight: 600;
}
